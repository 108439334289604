import React from "react";
import {withTranslation} from "react-i18next";
import bcn from "../../assets/images/camisetabcn.png";
import traveler from "../../assets/images/maleta.svg";
import line from "../../assets/images/line.png";

const FigureConsum:React.FC  = ({logo,SI, description, bold, numberBcn,numberJam, t}:any) => {


        return (
          <>
            <div
              className="flex flex-col w-full h-auto lg:pb-5 content-consum bg-background-primary
                        rounded-3xl content-center justify-start text-center lg:gap-4 2xl:w-full 2xl:mb-auto"
            >
              {logo && (
                <div className="flex self-center div-consum">
                  <img
                    className="img-consum self-center lg:my-5"
                    src={logo}
                    alt=""
                  />
                </div>
              )}
              <img src={line} alt="" />
              {SI && (
                <>
                  <div className="flex flex-col self-center">
                    <div className="grid grid-cols-3 p-2 lg:p-4 self-center items-center">
                      <img
                        className="img-consum self-center w-20"
                        src={traveler}
                        alt=""
                      />
                      <div className="col-span-2 flex flex-col self-center items-center w-full">
                        <h2 className="font-black self-center text-center text-primary">
                          {(Math.round(numberJam * 100) / 100).toFixed(0)}
                        </h2>
                        <div className="flex flex-row ">
                          <p className="text-primary text-base font-black ">
                            {SI}
                          </p>
                          <p className="text-primary text-base font-black">
                            {" "}
                            / {t("consum.guest")}
                          </p>
                        </div>
                      </div>
                    </div>
                    {numberBcn !== undefined && (
                      <div className="grid grid-cols-3 p-4 self-start items-center">
                        <img
                          className="img-consum self-center w-20"
                          src={bcn}
                          alt=""
                        />
                        <div className="col-span-2 flex flex-col self-center items-center w-full">
                          <h2 className="font-black self-center text-center text-primary">
                            {(Math.round(numberBcn * 100) / 100).toFixed(0)}
                          </h2>
                          <div className="flex flex-row ">
                            <p className="text-primary text-base font-black ">
                              {SI}
                            </p>
                            <p className="text-primary text-base font-black">
                              {" "}
                              / {t("consum.resident")}
                            </p>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </>
              )}
              {bold && (
                <div
                  className="text-2xl text-left px-2 font-bold"
                  dangerouslySetInnerHTML={{ __html: bold }}
                ></div>
              )}
              {description && (
                <div
                  className="lg:-mt-4 text-left px-2"
                  dangerouslySetInnerHTML={{ __html: description }}
                ></div>
              )}
            </div>
          </>
        );

}


export default withTranslation('common')(FigureConsum);
